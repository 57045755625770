<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>
    <section>
      <div v-if="this.$store.state.timetable !== null">
        <div id="main">
          <TimeTableData />
        </div>
        <div
          id="mobileOptions"
          class="textSubTitleNormal"
        >
          <VBtn
            color="primary"
            round
            depressed
            outline
            small
            @click="selectDay('monday')"
          >
            monday
          </VBtn>
          <VBtn
            color="primary"
            round
            depressed
            outline
            small
            @click="selectDay('tuesday')"
          >
            tuesday
          </VBtn>
          <VBtn
            color="primary"
            round
            depressed
            outline
            small
            @click="selectDay('wednesday')"
          >
            wednesday
          </VBtn>
          <VBtn
            color="primary"
            round
            depressed
            outline
            small
            @click="selectDay('thursday')"
          >
            thursday
          </VBtn>
          <VBtn
            color="primary"
            round
            depressed
            outline
            small
            @click="selectDay('friday')"
          >
            friday
          </VBtn>
        </div>

        <div id="mobileTT">
          <TimeTableMobileView :date="day" />
        </div>
        <div v-if="this.$store.state.timetable.status === 'invalid'">
          <NoResult />
        </div>
      </div>
      <div
        v-else
        id="loading"
      >
        <Loading />
      </div>
    </section>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../configuration/api';
import Loading from '../components/Loading.vue';
import NoResult from '../components/NoResult.vue';
import TimeTableData from '../components/TimeTableData.vue';
import TimeTableMobileView from '../components/TimeTableMobileView.vue';

export default {
  components: {
    Loading,
    NoResult,
    TimeTableData,
    TimeTableMobileView,
  },

  data: () => ({
    pageTitle: 'timetable',
    data: '',
    day: '',
  }),

  beforeCreate() {
    axios.get(`${api}/timetable/${this.$store.state.studentInfo.class}`)
      .then((res) => {
        this.data = res.data;
        this.$store.commit('setTimetable', this.data);
      })
      .catch(() => {
        this.retry();
      });
  },
  mounted() {
    this.day = moment().format('dddd').toLowerCase();
    this.selectDay(this.day);
  },

  methods: {
    selectDay(day) {
      this.day = day;
    },

    view(id) {
      this.$router.push(`${this.$route.path}/${id}`);
    },

    retry() {
      setTimeout(() => {
        axios.get(`${api}/timetable/${this.$store.state.studentInfo.class}`)
          .then((res) => {
            this.data = res.data.data[0].data;
            this.$store.commit('setTimetable', this.data);
          })
          .catch(() => {
            setTimeout(() => {
              this.retry();
            }, 5000);
          });
      }, 2500);
    },
  },

};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
@import '../assets/stylus/timetable';
</style>
