<template>
  <section>
    <VCard
      v-if="date === 'monday'"
      class="mobileCard"
    >
      <p class="textTitle">
        Monday
      </p>
      <p class="textSubTitleNormal textBold mb-4">
        {{ stdClass }}
      </p>
      <div class="mt-3">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          1st Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p1 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          2nd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p2 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          3rd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p3 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          4th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p4 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          5th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p5 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          6th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p6 }}
        </p>
      </div>
      <div
        v-if="this.$store.state.timetable.data[0].data.day.monday.p7 !== null"
        class="mt-2 pb-4"
      >
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          7th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.monday.p7 }}
        </p>
      </div>
    </VCard>

    <VCard
      v-if="date === 'tuesday'"
      class="mobileCard"
    >
      <p class="textTitle">
        Tuesday
      </p>
      <p class="textSubTitleNormal textBold mb-4">
        {{ stdClass }}
      </p>
      <div class="mt-3">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          1st Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p1 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          2nd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p2 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          3rd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p3 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          4th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p4 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          5th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p5 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          6th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p6 }}
        </p>
      </div>
      <div
        v-if="this.$store.state.timetable.data[0].data.day.tuesday.p7 !== null"
        class="mt-2 pb-4"
      >
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          7th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.tuesday.p7 }}
        </p>
      </div>
    </VCard>

    <VCard
      v-if="date === 'wednesday'"
      class="mobileCard"
    >
      <p class="textTitle">
        Wednesday
      </p>
      <p class="textSubTitleNormal textBold mb-4">
        {{ stdClass }}
      </p>
      <div class="mt-3">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          1st Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p1 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          2nd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p2 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          3rd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p3 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          4th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p4 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          5th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p5 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          6th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p6 }}
        </p>
      </div>
      <div
        v-if="this.$store.state.timetable.data[0].data.day.wednesday.p7 !== null"
        class="mt-2 pb-4"
      >
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          7th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.wednesday.p7 }}
        </p>
      </div>
    </VCard>

    <VCard
      v-if="date === 'thursday'"
      class="mobileCard"
    >
      <p class="textTitle">
        Thursday
      </p>
      <p class="textSubTitleNormal textBold mb-4">
        {{ stdClass }}
      </p>
      <div class="mt-3">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          1st Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p1 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          2nd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p2 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          3rd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p3 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          4th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p4 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          5th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p5 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          6th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p6 }}
        </p>
      </div>
      <div
        v-if="this.$store.state.timetable.data[0].data.day.thursday.p7 !== null"
        class="mt-2 pb-4"
      >
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          7th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.thursday.p7 }}
        </p>
      </div>
    </VCard>

    <VCard
      v-if="date === 'friday'"
      class="mobileCard"
    >
      <p class="textTitle">
        Friday
      </p>
      <p class="textSubTitleNormal textBold mb-4">
        {{ stdClass }}
      </p>
      <div class="mt-3">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          1st Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p1 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          2nd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p2 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          3rd Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p3 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          4th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p4 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          5th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p5 }}
        </p>
      </div>
      <div class="mt-2">
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          6th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p6 }}
        </p>
      </div>
      <div
        v-if="this.$store.state.timetable.data[0].data.day.friday.p7 !== null"
        class="mt-2 pb-4"
      >
        <p class="textSubTitleNormal textBold textSmall fontColorSub text-uppercase">
          7th Period
        </p>
        <p class="textSubTitle textBold">
          {{ this.$store.state.timetable.data[0].data.day.friday.p7 }}
        </p>
      </div>
    </VCard>

    <VCard
      v-if="date === 'saturday'"
      class="mobileHoliday"
    >
      <div>
        <p class="textTitle">
          IT'S SATURDAY
        </p>
        <p class="textTitle">
          Take the day off and enjoy life!
        </p>
      </div>
    </VCard>

    <VCard
      v-if="date === 'sunday'"
      class="mobileHoliday"
    >
      <div>
        <p class="textTitle">
          IT'S SUNDAY
        </p>
        <p class="textTitle">
          Take the day off and enjoy life!
        </p>
      </div>
    </VCard>
  </section>
</template>

<script>
export default {
  props: ['date'],
  computed: {
    stdClass() {
      return `Class ${this.$store.state.timetable.data[0].data.class}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
@import '../assets/stylus/timetable';
</style>
